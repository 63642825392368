import axios from 'axios';
import {LoadLocalStorage} from "./app/utils/Utilities";

var url = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
    baseURL: url
});


console.log("Connected to: ", url);
console.log("Company Id: ", LoadLocalStorage('selectedCompany')?.id ?? null);

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['CompanyId'] = LoadLocalStorage('selectedCompany')?.id ?? null;
        return config;
    },
    (error) => Promise.reject(error)
);


axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong!')
);

export default axiosInstance;
